import React from "react";

const Footer = () => {
  return (
    <>
      <footer id="footer" className="w-100 bg-light text-center footer">
        © 2020 Maah Asghar. All Rights Reserved
      </footer>
    </>
  );
};

export default Footer;
